import React from "react"
import Layout from "../components/layout"
import Header from "../components/header";
import Helmet from "react-helmet";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";

const IndexPage = ({data}) => {
    let impressumPageData = data.allStrapiImpressumPages.edges[0].node;
    let offersData = data.allStrapiOfferPages.edges;
    return (
    <Layout page="impressum">
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Impressum | Paixon</title>
            <meta name="description" content="Das Impressum von Paixon, den Schweizer Voice- und Chatbot Experten. Stöbern Sie auf unserer Webseite und erfahren Sie mehr zu virtuellen Assistenten."/>
        </Helmet>
        <Header className="impressum" slogan="" offersData={offersData}/>
        <main className="container impressum pt">
            <h1>Impressum</h1>
            <ReactMarkdown children={impressumPageData.content}/>
        </main>
    </Layout>
)}

export default IndexPage


export const pageQuery = graphql`  
  query ImpressumPageQuery {
  allStrapiImpressumPages {
    edges {
      node {
        content
        id
      }
    }
  }
   allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
 }
`

